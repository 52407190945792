"use strict";

document.addEventListener("ready", () => {});

window.addEventListener("load", () => {
  // Preloader
  document.querySelector("body").classList.add("page-loaded");

  // jQ Tilt init
  jQuery("[data-tilt]").tilt();

  // Typewriter
  jQuery("#typewriter").typewriter({
    prefix: "",
    text: ["Please Wait", "Still Loading"],
    typeDelay: 100,
    waitingTime: 1500,
    blinkSpeed: 800
  });

  // WOW Animation
  const wow = new WOW({
    animateClass: "animated",
    offset: 0
  });
  wow.init();

  // SLIDER
  var swiper_loop = true;
  var autoplay = {
    delay: jQuery(".swiper-slider").data("autoplay-delay"),
    disableOnInteraction: false
  };
  if (jQuery(".swiper-slider").data("loop") === "disable") {
    swiper_loop = false;
    autoplay = false;
  }

  var swiper = new Swiper(".swiper-slider", {
    speed: jQuery(".swiper-slider").data("speed"),
    autoplay: autoplay,
    loop: swiper_loop,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function(index, className) {
        return '<span class="' + className + '">0' + (index + 1) + "</span>";
      }
    }
  });

  var pageSection = jQuery(".bg-image");
  pageSection.each(function(indx) {
    if (jQuery(this).attr("data-background")) {
      jQuery(this).css(
        "background-image",
        "url(" + jQuery(this).data("background") + ")"
      );
    }
  });

  // HAMBURGER MENU
  $(".hamburger-menu").on("click", function(e) {
    if ($(".navigation-menu").hasClass("active")) {
      $(".hamburger").toggleClass("open");
      $("body").toggleClass("overflow");
      $(".navigation-menu").removeClass("active");
      $(".navigation-menu .inner .menu").css("transition-delay", "0s");
      $(".navigation-menu .inner blockquote").css("transition-delay", "0s");
      $(".navigation-menu .bg-layers span").css("transition-delay", "0.3s");
    } else {
      $(".navigation-menu").addClass("active");
      $(".hamburger").toggleClass("open");
      $("body").toggleClass("overflow");
      $(".navigation-menu.active .inner .menu").css(
        "transition-delay",
        "0.45s"
      );
      $(".navigation-menu.active .inner blockquote").css(
        "transition-delay",
        "0.50s"
      );
      $(".navigation-menu .bg-layers span").css("transition-delay", "0s");
    }
    $(this).toggleClass("active");
  });

  // PAGE TRANSITION
  //For mobile add class
  var $window = $(window),
    $class_selector = $(".brabus-nav > ul");

  $window
    .resize(function resize() {
      if ($window.width() < 767) {
        $class_selector.addClass("is-mobile");
      } else {
        $(".navigation-menu").removeClass("active");
        $(".hamburger").removeClass("open");
        $("body").removeClass("overflow");
        $class_selector.removeClass("is-mobile");
        $(".dropdown").removeClass("active");
      }
    })
    .trigger("resize");

  $("body .brabus-nav > ul a").on("click", function(e) {
    var that = this;
    if (
      !$(this)
        .parent()
        .hasClass("has-dropdown")
    ) {
      var url = this.getAttribute("href");
      if (url.indexOf("#") != -1) {
        var hash = url.substring(url.indexOf("#"));

        if ($("body " + hash).length != 0) {
          $(".transition-overlay").removeClass("active");
          $(".hamburger").toggleClass("open");
          $("body").toggleClass("overflow");
          $(".navigation-menu").removeClass("active");
          $(".navigation-menu .inner ul").css("transition-delay", "0s");
          $(".navigation-menu .inner blockquote").css("transition-delay", "0s");
          $(".navigation-menu .bg-layers span").css("transition-delay", "0.3s");

          $("html, body").animate(
            {
              scrollTop: $(hash).offset().top
            },
            1000
          );
        }
      } else {
        $(".transition-overlay").toggleClass("active");
        setTimeout(function() {
          window.location = url;
        }, 1000);
      }
    } else {
      if ($(".brabus-nav > ul").hasClass("is-mobile")) {
        e.preventDefault();
        $(that)
          .next()
          .toggleClass("active");

        if (
          !jQuery(that)
            .next("ul")
            .hasClass("active")
        ) {
          $(".brabus-nav ul").removeClass("active");
        }
      }
    }
  });

  // PAGE HEADER FADE
  var divs = $("header");
  $(window).on("scroll", function() {
    var st = $(this).scrollTop();
    divs.css({ opacity: 1 - st / 700 });
    divs.css({ "transition-delay": "0s" });
    divs.css({ transition: "0.05s ease-in-out" });
  });
});
